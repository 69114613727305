import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FileUploadInput, FileUploader, FormInput, FormSelect } from "suregifts-component-lib";
import { CreateEditMerchantModel, useGetCategories, useGetMerchantNames, useGetPaymentTypes } from "../../../../services/merchants";

export const MerchantAddEditForm = () => {
  const { data: categories } = useGetCategories();
  const { data: paymentTypes } = useGetPaymentTypes();
  const { data: merchantNames } = useGetMerchantNames();
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<CreateEditMerchantModel>();
  console.log(watch());

  const isSuperMerchant = watch("isSuperMerchant");
  return (
    <>
      <Stack spacing={2}>
        <Stack spacing={"1.25rem"} direction="row">
          <FormInput label="Name" {...register("name")} helperText={errors.name?.message} />
          <FormInput label="Redemption Link Key" {...register("redemptionKey")} helperText={errors.redemptionKey?.message} />
        </Stack>
        <Box
          sx={{
            display: "flex",
            gridTemplateColumns: "1fr 1fr",
            "& > div": {
              width: "50%",
            },
            gap: "1.25rem",
          }}
        >
          <FormInput label="Email" {...register("email")} helperText={errors.email?.message} />
          <FormInput label="Phone" {...register("phone")} helperText={errors.phone?.message} />
        </Box>
        <Stack spacing={"1.25rem"} direction="row">
          <FormInput label="Swap Rate" type="text" {...register("swapRate")} helperText={errors.swapRate?.message} />
          <Controller
            control={control}
            name="paymentType"
            render={({ field }) => <FormSelect helperText={errors.paymentType?.message} {...field} label={"Payment Type"} options={paymentTypes} />}
          />
        </Stack>
        <Stack spacing={"1.25rem"} direction="row" style={{ alignItems: "center" }}>
          <div style={{ width: "50%" }}>
            <Controller
              control={control}
              name="isSuperMerchant"
              render={({ field }) => <FormControlLabel control={<Checkbox checked={field.value} onChange={field.onChange} />} label="Is Super Merchant" />}
            />
          </div>
          {!isSuperMerchant && (
            <Controller
              control={control}
              name="superMerchantId"
              render={({ field }) => <FormSelect fullWidth={false} width="50%" {...field} label={"Super Merchant"} options={merchantNames} />}
            />
          )}
        </Stack>
        <Stack spacing={"1.25rem"} direction="row">
          <FormInput label="Minimum Price" {...register("minAmount")} helperText={errors.minAmount?.message} />
        </Stack>
        <Stack spacing={"1.25rem"} direction="row">
          <FormInput label="Payment Margin(%)" {...register("paymentMargin")} helperText={errors.paymentMargin?.message} />
          <Controller
            name="photos"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FileUploader
                  multiple
                  onChange={(files) => {
                    onChange(files);
                  }}
                  renderControl={(uploadControl) => (
                    <FileUploadInput value={value} {...uploadControl} label="Upload file" helperText={errors.photos?.message} />
                  )}
                />
              );
            }}
          />
        </Stack>

        <Stack spacing={"1.25rem"} direction="row">
          <FormInput multiline={true} label="Redmeption Details" {...register("redmeptionDetail")} helperText={errors.redmeptionDetail?.message} />
        </Stack>
        <FormInput multiline={true} size="medium" label="Address" {...register("address")} helperText={errors.address?.message} />
        <Controller
          control={control}
          name="categories"
          render={({ field }) => (
            <FormSelect multiple helperText={errors.categories?.message} {...field} label={"Categories"} options={categories?.filter((x) => !x.isExternal)} />
          )}
        />
        <FormInput multiline label="Description" size="medium" {...register("description")} helperText={errors.address?.message} />
      </Stack>
    </>
  );
};
