import { useNavigate, useParams } from "react-router-dom";
import { useGetPackages } from "../../../../services/utility";
import ListView from "./ListView";
import { PageHeaderTitle, SearchContainer, useDocumentTitle } from "suregifts-component-lib";
import { BackLinkView } from "../../../../components/molecules/BackLinkView";
import { useMemo } from "react";

function PackagesScreen() {
  const { billerId } = useParams();
  const { data, refetch } = useGetPackages(billerId!);
  useDocumentTitle("Packages");
  const navigate = useNavigate();
  const firstPackage = useMemo(() => data?.at(0), [data]);
  return (
    <div>
      <BackLinkView
        label={`${data?.at(0)?.categoryName} Billers`}
        onClick={() => {
          navigate(`/super-admin/${data?.at(0)?.categoryId}/billers`);
        }}
      />
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={`${firstPackage?.billerName} Packages`} />
      </SearchContainer>
      <ListView data={data} reload={refetch} />
    </div>
  );
}

export default PackagesScreen;
