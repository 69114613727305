import { TableHead, TableRow, TableCell, IconButton, Box } from "@mui/material";
import {
  dateOnly,
  IOSSwitch,
  MainTable,
  MarginTop40Box,
  PageFilter,
  PageHeaderTitle,
  Pagination,
  SearchButton,
  SearchContainer,
  SearchInput,
  TableBody,
  useDocumentTitle,
  useFilter,
  useNotificationContext,
  usePagination,
} from "suregifts-component-lib";

import { fixedCellStyle } from "../../../helpers/cssHelper";
import { CampaignDto, useChangeCampaignStatus, useDeleteCampaign, useGetCampaigns } from "../../../services/merchant-backend/general";
import { EditPencilIcon, DeleteIcon } from "suregifts-component-lib";
import { useLocation, useNavigate } from "react-router-dom";
import { AddCampaignModal } from "./AddCampaignModal";
import { toast } from "react-toastify";
import { EditCampaignModal } from "./EditCampaignModal";

function CampaignScreen() {
  useDocumentTitle("Campaigns");

  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const { data, isLoading: isLoading, refetch: reloadCampaigns } = useGetCampaigns(filter);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { confirm } = useNotificationContext();
  const { mutate: deleteCampaign, isLoading: isDeleting } = useDeleteCampaign();
  const { mutate: changeStatus, isLoading: isChangeStatus } = useChangeCampaignStatus();

  const pagefilter = usePagination(filter.page, data, filter.size);

  const isFetching = isDeleting || isChangeStatus || isLoading;

  const handleDelete = (item: CampaignDto) => {
    confirm({
      onConfirm: () =>
        deleteCampaign(item.id, {
          onSuccess: () => {
            toast.success("Record deleted successfully");
            reloadCampaigns();
          },
        }),
    });
  };

  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  return (
    <div>
      <MarginTop40Box>
        <SearchContainer>
          <PageHeaderTitle title="Campaigns" />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
            <SearchButton
              sx={{ py: "0.125rem" }}
              label="Add Campaign"
              onClick={() => {
                navigate("./", { state: { pageType: "ADD" } });
              }}
            />
          </Box>
        </SearchContainer>
      </MarginTop40Box>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: "60%" }}>Title</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pagefilter.data?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.title}</TableCell>

              <TableCell style={fixedCellStyle}>{dateOnly(item.dateCreated)}</TableCell>

              <TableCell>
                <IconButton>
                  <IOSSwitch
                    checked={item.isActive}
                    onChange={() => {
                      changeStatus(item.id, {
                        onSuccess() {
                          toast.success("Record updated successfully.");
                          reloadCampaigns();
                        },
                      });
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate("./", { state: { data: item, pageType: "EDIT" } });
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDelete(item)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={pagefilter.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
      {state?.pageType === "ADD" && <AddCampaignModal />}
      {state?.pageType === "EDIT" && <EditCampaignModal />}
    </div>
  );
}

export default CampaignScreen;
