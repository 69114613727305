import { useFormContext, Controller } from "react-hook-form";
import { FileUploader, FileUploadInput } from "suregifts-component-lib";
export type CustomFormControlProps = {
  name: string;
  label: string;
};
export const FormFileSelector = ({ name }: CustomFormControlProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <FileUploader
            onChange={(files) => {
              onChange(files);
            }}
            renderControl={(uploadControl) => (
              <FileUploadInput value={value} {...uploadControl} label="Upload file" helperText={errors[name]?.message as string} />
            )}
          />
        );
      }}
    />
  );
};
