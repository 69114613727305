import { TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BooleanLabel, EditPencilIcon, formatMoney, IOSSwitch, MainTable } from "suregifts-component-lib";
import { PackageHouseData, useConfigurePackage } from "../../../../services/utility";
import { ConfigurePackageModal } from "./ConfigurePackageModal";
import { useQueryClient } from "react-query";

type ListViewProps = {
  data?: PackageHouseData[];
  reload?: () => void;
};

function ListView({ data = [], reload = () => {} }: ListViewProps) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mutate: configure } = useConfigurePackage();

  return (
    <>
      <MainTable sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Biller Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.packageId}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.provider}</TableCell>
              <TableCell>{item.billerName}</TableCell>
              <TableCell>{formatMoney(item.amount, item.currency)}</TableCell>
              <TableCell>
                <IconButton>
                  <IOSSwitch
                    checked={item.isActive}
                    onChange={() => {
                      configure(
                        { ...item, isActive: !item.isActive },
                        {
                          onSuccess() {
                            reload();
                          },
                        }
                      );
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate("./", { state: { data: item, pageType: "EDIT" } });
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      {state?.pageType === "EDIT" && <ConfigurePackageModal />}
    </>
  );
}
export default ListView;
