import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { YupShape, DialogTitle, FormInput, FormButton } from "suregifts-component-lib";
import * as yup from "yup";

import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { ConfigurePackageModel, useConfigurePackage } from "../../../../services/utility";
import { OnOffSelectControl } from "../../../../components/molecules/OnOffSelectControl";

export const campaignSchema = yup
  .object<YupShape<ConfigurePackageModel>>({
    name: yup.string().required(),
  })
  .required();
export const ConfigurePackageModal = ({}) => {
  const { state } = useLocation();
  const formMethods = useForm<ConfigurePackageModel>({
    resolver: yupResolver(campaignSchema),
    values: { ...state?.data },
  });
  const {
    formState: { errors, isValid },
    register,
    getValues,
    handleSubmit,
  } = formMethods;
  const queryClient = useQueryClient();

  const { mutate: configurePackage, isLoading } = useConfigurePackage();

  const submitForm = (data) => {
    configurePackage(data, {
      onSuccess: () => {
        toast.success("Package configured successfully.");
        queryClient.invalidateQueries("packages");
        handleClose();
      },
    });
  };

  const nagivate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("./");
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog open maxWidth={"xs"} onClose={handleClose}>
        <DialogTitle title={`Configure ${getValues("name")}`} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={2}>
            <FormInput label="Name" {...register("name")} helperText={errors.name?.message} />
            <OnOffSelectControl label="Is Active" name="isActive" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormButton label="Submit" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
