import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { useForm, Controller, useFormContext, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { YupShape, DialogTitle, FormInput, FileUploader, FileUploadInput, FormButton, FormSelect } from "suregifts-component-lib";
import * as yup from "yup";

import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { ConfigureBillerModel, useConfigureBiller } from "../../../../services/utility";
import { FormFileSelector } from "../../../../components/molecules/FormFileSelector";
import { OnOffSelectControl } from "../../../../components/molecules/OnOffSelectControl";

export const campaignSchema = yup
  .object<YupShape<ConfigureBillerModel>>({
    name: yup.string().required(),
  })
  .required();
export const ConfigureBillerModal = ({}) => {
  const { state } = useLocation();
  const formMethods = useForm<ConfigureBillerModel>({
    resolver: yupResolver(campaignSchema),
    values: { ...state?.data },
  });
  const {
    formState: { errors, isValid },
    register,
    getValues,
    handleSubmit,
  } = formMethods;
  const queryClient = useQueryClient();

  const { mutate: configureProduct, isLoading } = useConfigureBiller();

  const submitForm = (data) => {
    configureProduct(data, {
      onSuccess: () => {
        toast.success("Biller configured successfully.");
        queryClient.invalidateQueries("billers");
        handleClose();
      },
    });
  };

  const nagivate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("./");
  };
  console.log(state.data);

  return (
    <FormProvider {...formMethods}>
      <Dialog open maxWidth={"xs"} onClose={handleClose}>
        <DialogTitle title={`Configure ${getValues("name")}`} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={2}>
            <FormInput label="Name" {...register("name")} helperText={errors.name?.message} />
            <FormInput label="surcharge" {...register("surcharge")} helperText={errors.surcharge?.message} />
            <OnOffSelectControl label="Is Active" name="isActive" />
            <FormInput label="Customer Filed" {...register("customerField")} helperText={errors.customerField?.message} />
            <FormFileSelector name="imageUrl" label="Select Image" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormButton label="Submit" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
