import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError, PageFilter } from "suregifts-component-lib";
import { CreateEditStoreModel } from "../../shared/merchants";

export const useGetVoucherSummary = () => {
  const axios = useAxios();
  return useQuery<VoucherSummaryData, ServerError>({
    queryKey: ["users-summary"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/merchant-backend/voucher-summary", {}),
  });
};
export const useGetMerchantSummary = () => {
  const axios = useAxios();
  return useQuery<MerchantSummary, ServerError>({
    queryKey: ["users-summary"],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/merchant-backend/merchant-summary", {}),
  });
};
export const useGetCampaigns = (filter: PageFilter) => {
  const axios = useAxios();
  return useQuery<CampaignDto[], ServerError>({
    queryKey: ["campaigns", filter],
    keepPreviousData: true,
    queryFn: () => axios.get("/general/api/merchant-backend/campaigns", {}),
    initialData: [],
  });
};
export const useCreateCampaign = () => {
  const axios = useAxios();
  return useMutation<CampaignDto, ServerError, CampaignModel>((model) => {
    return axios.post(`general/api/merchant-backend/campaigns`, model);
  });
};
export const useEditCampaign = (campaignId: number) => {
  const axios = useAxios();
  return useMutation<CampaignDto, ServerError, CampaignModel>((model) => {
    return axios.post(`general/api/merchant-backend/campaigns/${campaignId}`, model);
  });
};
export const useDeleteCampaign = () => {
  const axios = useAxios();
  return useMutation<any, ServerError, number>((campaignId) => {
    return axios.delete(`general/api/merchant-backend/campaigns/${campaignId}`);
  });
};
export const useChangeCampaignStatus = () => {
  const axios = useAxios();
  return useMutation<CampaignDto, ServerError, number>((campaignId) => {
    return axios.post(`general/api/merchant-backend/campaigns/${campaignId}/status`);
  });
};
export interface VoucherSummaryData {
  activations: string;
  redemptions: string;
  deactivations: string;
}
export interface MerchantSummary {
  allMerchants: string;
  pendingMerchants: string;
  newMerchants: string;
  inactiveMerchants: string;
}
export interface CampaignDto {
  id: number;
  title: string;
  description: string;
  link: string;
  imageUrl: string;
  isActive: boolean;
  dateCreated: string;
}
export interface CampaignModel {
  title: string;
  description: string;
  link: string;
  imageUrl: string;
}
