import { Stack } from "@mui/material";
import { YupShape, FormInput, FormButton, useAuthContext } from "suregifts-component-lib";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ProfileImageView from "./ProfileImageView";
import { UpdateProfileModel, useUpdateProfile } from "../../services/auth.api";

const schema = yup
  .object<YupShape<UpdateProfileModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  })
  .required();
export const UpdateProfileSection = () => {
  const { user, updateInfo } = useAuthContext();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<UpdateProfileModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  });

  const { mutate: updateProfile, isLoading } = useUpdateProfile();
  const submitForm = (model) => {
    updateProfile(model, {
      onSuccess: (data) => {
        updateInfo(data);
        toast.success("Password changed successfully.");
      },
    });
  };
  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(submitForm)}>
      <ProfileImageView />
      <Stack direction={"row"} spacing={2}>
        <FormInput required label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
        <FormInput required label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />
      </Stack>

      <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%" }}>
        <FormButton type="submit" label="Save Profile" disabled={!isValid} loading={isLoading} />
      </div>
    </Stack>
  );
};
