import { useMutation, useQuery } from "react-query";
import { useAxios, ServerError } from "suregifts-component-lib";

export interface CountryDto {
  name: string;
  code: string;
}

export interface ConfigureEsimModel {
  packageId: string;
  name: string;
  isActive: boolean;
  amount: number;
}

export interface EsimPackageDto {
  name: string;
  amount: number;
  value: number;
  currency: string;
  packageId: string;
  isActive: boolean;
  region: string;
}

export const useGetEsimPackages = (countryCode?: string) => {
  const axios = useAxios();

  const path = "/orders/api/esims";
  return useQuery<EsimPackageDto[], ServerError>({
    queryKey: ["esims", countryCode],
    keepPreviousData: true,
    initialData: [],
    queryFn: () =>
      axios.get(path, {
        params: { countryCode },
      }),
  });
};
export const useConfigureEsim = () => {
  const axios = useAxios();
  return useMutation<any, ServerError, ConfigureEsimModel>((model) => {
    return axios.post(`/orders/api/esims/configure`, model);
  });
};
export const useGetEsimCountries = () => {
  const axios = useAxios();

  const path = "/orders/api/esims/countries";
  return useQuery<CountryDto[], ServerError>({
    queryKey: ["esim-countries"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get(path, {}),
  });
};
