import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEditCampaign } from "../../../services/merchant-backend/general";
import { CampaignForm } from "./CampaignForm";

export const EditCampaignModal = () => {
  const queryClient = useQueryClient();

  const submitForm = (data) => {
    editCampaign(data, {
      onSuccess: () => {
        toast.success("Campaign edited successfully.");
        queryClient.invalidateQueries("campaigns");
        handleClose();
      },
    });
  };
  const { state } = useLocation();
  const nagivate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("./");
  };

  const { mutate: editCampaign, isLoading } = useEditCampaign(state.data.id);

  return <CampaignForm title={"Edit Campaign"} handleClose={handleClose} submitForm={submitForm} isLoading={isLoading} />;
};
