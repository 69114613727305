import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { SwapCategoryModel, useGetCategories, useSwapCategory } from "../../../services/merchants";
import { FormSelectControl } from "../../../components/molecules/FormSelectControl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

function CategorySwapModal() {
  const formMethods = useForm<SwapCategoryModel>({
    defaultValues: { fromCatId: "", toCartId: "" },
  });
  const navigate = useNavigate();
  const { mutate: swap, isLoading } = useSwapCategory();
  const { data: categories } = useGetCategories();

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;
  const handleClose = () => {
    if (isLoading) return;
    navigate("./");
  };
  const queryClient = useQueryClient();
  const submitForm = (data) => {
    swap(data, {
      onSuccess: () => {
        toast.success("Category swapped successfully");
        queryClient.invalidateQueries("products");
        handleClose();
      },
    });
  };
  return (
    <FormProvider {...formMethods}>
      <Dialog open maxWidth={"xs"} onClose={handleClose}>
        <DialogTitle title={`Configure Category`} handleClose={handleClose} />
        <DialogContent>
          <Stack spacing={2}>
            <FormSelectControl label="Category" name="fromCatId" options={categories?.filter((e) => e.isExternal)} />
            <FormSelectControl label="New Category" name="toCartId" options={categories?.filter((e) => !e.isExternal)} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormButton label="Submit" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

export default CategorySwapModal;
