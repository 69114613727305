import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { YupShape, DialogTitle, FormInput, FileUploader, FileUploadInput, FormButton } from "suregifts-component-lib";
import { CampaignModel } from "../../../services/merchant-backend/general";
import * as yup from "yup";

export const campaignSchema = yup
  .object<YupShape<CampaignModel>>({
    title: yup.string().required(),
    description: yup.string().required(),
    link: yup.string().required().url(),
  })
  .required();
export const CampaignForm = ({ handleClose, submitForm, isLoading, title }) => {
  const { state } = useLocation();
  const {
    control,
    formState: { errors, isValid },
    register,
    handleSubmit,
  } = useForm<CampaignModel>({
    resolver: yupResolver(campaignSchema),
    values: { ...state?.data },
  });

  return (
    <Dialog open maxWidth={"xs"} onClose={handleClose}>
      <DialogTitle title={title} handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput multiline size="medium" required label="Title" {...register("title")} helperText={errors.title?.message} />

          <FormInput multiline size="medium" required label="Description" {...register("description")} helperText={errors.description?.message} />

          <FormInput required label="Link" {...register("link")} helperText={errors.link?.message} />
          <Controller
            name="imageUrl"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FileUploader
                  onChange={(files) => {
                    onChange(files);
                  }}
                  renderControl={(uploadControl) => (
                    <FileUploadInput value={value} {...uploadControl} label="Upload file" helperText={errors.imageUrl?.message} />
                  )}
                />
              );
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Submit" disabled={!isValid} loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
};
