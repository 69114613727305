import { useFormContext, Controller } from "react-hook-form";
import { NameAndId, FormSelect } from "suregifts-component-lib";
import { CustomFormControlProps } from "./FormFileSelector";

type FormSelectControlProps = {
  options?: NameAndId[];
} & CustomFormControlProps;
export const FormSelectControl = ({ name, label, options }: FormSelectControlProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <FormSelect helperText={errors?.[name]?.message as any} options={options} label={label} {...field} />}
    />
  );
};
