import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateCampaign } from "../../../services/merchant-backend/general";
import { CampaignForm } from "./CampaignForm";

export const AddCampaignModal = () => {
  const queryClient = useQueryClient();

  const submitForm = (data) => {
    createCampaign(data, {
      onSuccess: () => {
        toast.success("Campaign created successfully.");
        queryClient.invalidateQueries("campaigns");
        handleClose();
      },
    });
  };

  const nagivate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    nagivate("./");
  };

  const { mutate: createCampaign, isLoading } = useCreateCampaign();

  return <CampaignForm title="Add Campaign" handleClose={handleClose} submitForm={submitForm} isLoading={isLoading} />;
};
