import { useNavigate, useParams } from "react-router-dom";
import { useGetBillers } from "../../../../services/utility";
import ListView from "./ListView";
import { PageHeaderTitle, SearchContainer, useDocumentTitle } from "suregifts-component-lib";
import { BackLinkView } from "../../../../components/molecules/BackLinkView";
import { useMemo } from "react";

function BillersScreen() {
  useDocumentTitle("Billers");
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const { data, refetch } = useGetBillers(categoryId!);
  const firstBiller = useMemo(() => data?.at(0), [data]);
  return (
    <div>
      <BackLinkView
        label="Categories"
        onClick={() => {
          navigate("/super-admin/bill-categories");
        }}
      />
      <SearchContainer sx={{ marginTop: "40px" }}>
        <PageHeaderTitle title={`${firstBiller?.categoryName} Billers`} />
      </SearchContainer>
      <ListView data={data} reload={refetch} />
    </div>
  );
}

export default BillersScreen;
