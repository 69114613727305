import { TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BooleanLabel, EditPencilIcon, formatMoney, IOSSwitch, MainTable, PageFilter, Pagination, useFilter, usePagination } from "suregifts-component-lib";
import { BillerHouseData, useConfigureBiller } from "../../../../services/utility";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ConfigureBillerModal } from "./ConfigureBillerModal";
import { useQueryClient } from "react-query";

type ListViewProps = {
  data?: BillerHouseData[];
  reload?: () => void;
};

function ListView({ data = [], reload = () => {} }: ListViewProps) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "", size: 10 });
  const { data: billers, total } = usePagination(filter.page, data, filter.size);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const queryClient = useQueryClient();
  const { mutate: configure } = useConfigureBiller();

  return (
    <>
      <MainTable sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Customer Field</TableCell>
            <TableCell>Surchange</TableCell>
            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billers?.map((item) => (
            <TableRow key={item.billerId}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.provideType}</TableCell>
              <TableCell>{item.customerField}</TableCell>
              <TableCell>{formatMoney(item.surcharge, item.currency)}</TableCell>
              <TableCell>
                <IconButton>
                  <IOSSwitch
                    checked={item.isActive}
                    onChange={() => {
                      configure(
                        { ...item, isActive: !item.isActive },
                        {
                          onSuccess() {
                            queryClient.invalidateQueries("billers");
                          },
                        }
                      );
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate("./", { state: { data: item, pageType: "EDIT" } });
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate(`/super-admin/${item.billerId}/packages`);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      {data.length != billers.length && (
        <div style={{ marginTop: "1.25rem" }}>
          <Pagination total={data?.length} page={filter.page} onChange={(page) => handleFilter({ page })} />
        </div>
      )}
      {state?.pageType === "EDIT" && <ConfigureBillerModal />}
    </>
  );
}
export default ListView;
