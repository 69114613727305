import { useFormContext, Controller } from "react-hook-form";
import { FormSelect } from "suregifts-component-lib";
import { CustomFormControlProps } from "./FormFileSelector";

export const OnOffSelectControl = ({ name, label }: CustomFormControlProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormSelect
          helperText={errors[name]?.message as any}
          {...field}
          label={label}
          options={[
            { id: true, name: "Yes" },
            { id: false, name: "No" },
          ]}
        />
      )}
    />
  );
};
