import {
  BooleanLabel,
  EditPencilIcon,
  FilterDropdown,
  formatNumber,
  IOSSwitch,
  MainTable,
  MarginTop40Box,
  PageFilter,
  PageHeaderTitle,
  Pagination,
  SearchButton,
  SearchContainer,
  SearchInput,
  TableBody,
  useDocumentTitle,
  useFilter,
} from "suregifts-component-lib";
import { ProductDto, useConfigureProduct, useGetProducts } from "../../../services/merchants";
import { TableHead, TableRow, TableCell, IconButton, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigureProductModal } from "./ConfigureProductModal";
import { toast } from "react-toastify";
import CategorySwapModal from "./CategorySwapModal";
type ProductFilter = {
  provider: any;
} & PageFilter;
function ExternalProductScreen() {
  useDocumentTitle("External Gift Products");
  const navigate = useNavigate();

  const [filter, setFilter] = useFilter<ProductFilter>({ page: 1, search: "", size: 10 });
  const { data, isLoading: isLoading, refetch } = useGetProducts(filter);
  const { state } = useLocation();
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { mutate: configure, isLoading: isSending } = useConfigureProduct();
  const changeStatus = (item: ProductDto) => {
    const model = { ...item, isActive: !item.isActive, categoryIds: item.categories?.map((i) => i.id) };
    configure(model, {
      onSuccess: () => {
        refetch();
        toast.success("record updated successfully");
      },
    });
  };
  return (
    <div>
      <MarginTop40Box>
        <SearchContainer>
          <PageHeaderTitle title="External Gift Products" />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <FilterDropdown
              display="Provider"
              value={filter.provider}
              options={[
                { text: "All" },
                { value: 2, text: "Naivas" },
                {
                  value: 3,
                  text: "Oglobal",
                },
                {
                  value: 4,
                  text: "Runa",
                },
                {
                  value: 5,
                  text: "Bamboo",
                },
              ]}
              onChange={(index, value) => {
                handleFilter({ provider: value, page: 1 });
              }}
            />
            <SearchInput
              value={filter.search}
              onChange={(e) => {
                handleFilter({ search: e, page: 1 });
              }}
            />
            <SearchButton sx={{ py: "0.125rem" }} label="Swap Category" onClick={() => navigate("./", { state: { pageType: "SWAPCATEGORY" } })} />
          </Box>
        </SearchContainer>
      </MarginTop40Box>
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Swapable</TableCell>
            <TableCell>Payment Margin</TableCell>
            <TableCell>Discount Margin</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items?.map((item) => (
            <TableRow key={item.productId}>
              <TableCell>{item.name}</TableCell>
              <TableCell style={{ maxWidth: "20%", width: "20%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                <span style={{ textOverflow: "ellipsis" }}> {item.categories.map((i) => i.name).join(", ")}</span>
              </TableCell>
              <TableCell>{item.vendorType}</TableCell>

              <TableCell>{item.isSwapable ? formatNumber(item.swapRate) + "%" : <BooleanLabel value={item.isSwapable} type="yesno" />}</TableCell>
              <TableCell>{formatNumber(item.paymentMargin)}%</TableCell>
              <TableCell>{formatNumber(item.orderDiscountMargin)}%</TableCell>

              <TableCell>
                <IconButton>
                  <IOSSwitch checked={item.isActive} onChange={() => changeStatus(item)} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate("./", { state: { data: item, pageType: "EDIT" } });
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} size={filter.size} onChange={(page) => handleFilter({ page })} />
      </div>
      {state?.pageType === "EDIT" && <ConfigureProductModal />}
      {state?.pageType === "SWAPCATEGORY" && <CategorySwapModal />}
    </div>
  );
}

export default ExternalProductScreen;
