import {
  EditPencilIcon,
  formatMoney,
  IOSSwitch,
  MainTable,
  MarginTop40Box,
  PageFilter,
  PageHeaderTitle,
  Pagination,
  SearchContainer,
  TableBody,
  useDocumentTitle,
  useFilter,
  usePagination,
} from "suregifts-component-lib";
import { TableHead, TableRow, TableCell, IconButton, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { AirtimeDto, useConfigureAirtime, useGetAirtimes } from "../../../../services/utility";
import ConfigureAirtimeModal from "./ConfigureAirtimeModal";
type EsimFilter = { countryCode } & PageFilter;

function AirtimeScreen() {
  useDocumentTitle("Airtimes");
  const navigate = useNavigate();
  const [filter, setFilter] = useFilter<EsimFilter>({ page: 1 });
  const { data: airtimes, isLoading: isLoading, refetch } = useGetAirtimes();

  const { mutate: configure } = useConfigureAirtime();
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { state } = useLocation();
  const { data, total } = usePagination(filter.page, airtimes, 10);
  const changeStatus = (item: AirtimeDto) => {
    configure(
      { ...item, isActive: !item.isActive },
      {
        onSuccess: () => {
          toast.success("Record updated successfully.");
          refetch();
        },
      }
    );
  };

  return (
    <div>
      <MarginTop40Box>
        <SearchContainer>
          <PageHeaderTitle title="Airtimes" />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          ></Box>
        </SearchContainer>
      </MarginTop40Box>
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Subname</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Min Value</TableCell>
            <TableCell>Max Value</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.airtimeId}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.subName}</TableCell>
              <TableCell>{item.provider}</TableCell>
              <TableCell>{formatMoney(item.minValue, item.currency)}</TableCell>
              <TableCell>{formatMoney(item.maxValue, item.currency)}</TableCell>

              <TableCell>
                <IconButton>
                  <IOSSwitch checked={item.isActive} onChange={() => changeStatus(item)} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate("./", { state: { data: item, pageType: "EDIT" } });
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={total} page={filter.page} size={filter.size} onChange={(page) => handleFilter({ page })} />
      </div>
      {state?.pageType === "EDIT" && <ConfigureAirtimeModal />}
    </div>
  );
}

export default AirtimeScreen;
