import {
  BooleanLabel,
  EditPencilIcon,
  FilterDropdown,
  formatMoney,
  IOSSwitch,
  MainTable,
  MarginTop40Box,
  OptionNaming,
  PageFilter,
  PageHeaderTitle,
  Pagination,
  SearchContainer,
  TableBody,
  useDocumentTitle,
  useFilter,
  usePagination,
} from "suregifts-component-lib";
import { TableHead, TableRow, TableCell, IconButton, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { EsimPackageDto, useConfigureEsim, useGetEsimCountries, useGetEsimPackages } from "../../../services/esim";
import { toast } from "react-toastify";
import ConfigureEsimModal from "./ConfigureEsimModal";
import { useMemo } from "react";
type EsimFilter = { countryCode } & PageFilter;
function EsimPackageScreen() {
  useDocumentTitle("Esims");
  const navigate = useNavigate();
  const [filter, setFilter] = useFilter<EsimFilter>({ page: 1 });
  const { data: esims, isLoading: isLoading, refetch } = useGetEsimPackages(filter.countryCode);

  const { mutate: configure } = useConfigureEsim();
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { state } = useLocation();
  const { data, total } = usePagination(filter.page, esims, 10);
  const changeStatus = (item: EsimPackageDto) => {
    configure(
      { ...item, isActive: !item.isActive },
      {
        onSuccess: () => {
          toast.success("Record updated successfully.");
          refetch();
        },
      }
    );
  };
  const { data: countryData } = useGetEsimCountries();
  const countries: OptionNaming[] = useMemo(() => {
    if (!countryData) return [];
    return [{ text: "All" }, ...countryData.map((e) => ({ text: e.name, value: e.code }))];
  }, [countryData]);
  return (
    <div>
      <MarginTop40Box>
        <SearchContainer>
          <PageHeaderTitle title="Esim Packages" />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <FilterDropdown
              display="Country"
              value={filter.countryCode}
              options={countries}
              onChange={(index, value) => {
                handleFilter({ countryCode: value, page: 1 });
              }}
            />
          </Box>
        </SearchContainer>
      </MarginTop40Box>
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Region</TableCell>
            <TableCell>Wholesale Price</TableCell>
            <TableCell>Retail Price</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.packageId}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.region}</TableCell>
              <TableCell>{formatMoney(item.value)}</TableCell>
              <TableCell>{formatMoney(item.amount)}</TableCell>

              <TableCell>
                <IconButton>
                  <IOSSwitch checked={item.isActive} onChange={() => changeStatus(item)} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigate("./", { state: { data: item, pageType: "EDIT" } });
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={total} page={filter.page} size={filter.size} onChange={(page) => handleFilter({ page })} />
      </div>
      {state?.pageType === "EDIT" && <ConfigureEsimModal />}
    </div>
  );
}

export default EsimPackageScreen;
