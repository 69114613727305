import { Box } from "@mui/material";
import { useGetCountries } from "../../../../services/utility";
import { SearchContainer, PageHeaderTitle, FilterDropdown } from "suregifts-component-lib";

type FilterSectionProps = {};

function FilterSection() {
  // const { data: countries } = useGetCountries((data) => {
  //   if (data.length) {
  //     const countryCode = data[0].id as string;
  //     filterChanged(countryCode);
  //   }
  // });
  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title={"Bill Categories"} />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      ></Box>
    </SearchContainer>
  );
}

export default FilterSection;
